<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="物资类别">
        <type-cascader
          v-model="filter.goodsTypeId"
          info="GoodsType"
        ></type-cascader>
      </el-form-item>
      <el-form-item label="物资">
        <base-select info="Goods" v-model="filter.goodsId"></base-select>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.storeId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getData"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      stripe
      border
      @row-dblclick="dblclick"
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
    >
      <el-table-column
        label="类型"
        prop="billName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="位置"
        prop="locationName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="日期" prop="billDate" width="90">
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="单号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="编号"
        prop="goodsCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="物资"
        prop="goodsName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="类别"
        prop="typeName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="规格"
        prop="goodsSpec"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单位"
        prop="goodsUnit"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column label="数量" prop="billQuantity" show-overflow-tooltip>
        <template slot-scope="scope">
          <span
            :style="{ color: scope.row.billQuantity < 0 ? 'red' : 'inherit' }"
            >{{ scope.row.billQuantity | thousands }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="billPrice" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.billPrice | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="金额" prop="amount" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{ color: scope.row.amount < 0 ? 'red' : 'inherit' }">{{
            scope.row.amount | thousands
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="累计数量"
        prop="balanceQuantity"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.balanceQuantity | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="成本" prop="balancePrice" show-overflow-tooltip>
        <template slot-scope="scope">{{
          scope.row.balancePrice | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="累计金额"
        prop="balanceAmount"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.balanceAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="detailRemark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import TypeCascader from "@/components/TypeCascader";
import setName from "@/common/setName";
import toExcel from "@/common/toExcel";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    BaseSelect,
    TypeCascader,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      (r) => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
    }
    this.getData();
  },
  methods: {
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = { ...this.filter };
      this.$get("GoodsStock/GetGoodsFlow", params)
        .then((r) => {
          r.forEach((item) => {
            this.getbillName(item);
            item.billDate = item.orderIndex.toString().substring(0, 8);
            item.amount = this.amountFormat(item.billPrice * item.billQuantity);
            item.balanceAmount = this.amountFormat(
              item.balancePrice * item.balanceQuantity
            );

            //1项目
            if (item.locationType == 1) {
              setName("Project", item, "locationId", "locationName");
            }
            //2仓库
            if (item.locationType == 2) {
              setName("Store", item, "locationId", "locationName");
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getbillName(item) {
      //期初 = 0, 采购 = 3,  调配 = 4,盘点 = 5,组装=11
      let res = "";
      switch (item.billType) {
        case 0:
          res = "期初";
          break;
        case 3:
          res = "物资采购";
          break;
        case 4:
          res = item.billQuantity < 0 ? "调配调出" : "调配调入";
          break;
        case 5:
          res = item.billQuantity < 0 ? "盘点盘亏" : "盘点盘盈";
          break;
        case 11:
          res = item.billQuantity < 0 ? "组装原材" : "组装产品";
          break;
      }
      item.billName = res;
    },
    dblclick(row) {
      //单据类型0期初3采购4调配5盘点
      switch (row.billType) {
        case 0:
          if (this.hasPermission("GoodsInit")) {
            this.$router.push({ name: "GoodsInit" });
          }
          break;
        case 3:
          if (this.hasPermission("Purchase")) {
            this.$router.push({
              name: "PurchaseItem",
              query: { id: row.billId },
            });
          }
          break;
        case 4:
          if (this.hasPermission("Allotted")) {
            this.$router.push({
              name: "AllottedItem",
              query: { id: row.billId },
            });
          }
          break;
        case 5:
          if (this.hasPermission("Inventory")) {
            this.$router.push({
              name: "InventoryDetail",
              params: { id: row.billId },
            });
          }
          break;
        case 11:
          if (this.hasPermission("Assembled")) {
            this.$router.push({
              name: "AssembledItem",
              query: { id: row.billId },
            });
          }
      }
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          locationName: item.locationName,
          billDate: this.$moment(item.billDate).format("YYYY-MM-DD"),
          billName: item.billName,
          billCode: item.billCode,
          goodsCode: item.goodsCode,
          goodsName: item.goodsName,
          typeName: item.typeName,
          goodsSpec: item.goodsSpec,
          goodsUnit: item.goodsUnit,
          billQuantity: item.billQuantity,
          billPrice: item.billPrice,
          amount: item.amount,
          balanceQuantity: item.balanceQuantity,
          balancePrice: item.balancePrice,
          balanceAmount: item.balanceAmount,
          detailRemark: item.detailRemark,
        });
      });
      let header = [
        "项目/仓库",
        "单据类型",
        "日期",
        "单号",
        "编号",
        "名称",
        "类型",
        "规格",
        "单位",
        "数量",
        "单价",
        "金额",
        "累计数量",
        "成本",
        "累计金额",
        "备注",
      ];
      let column = [
        "locationName",
        "billName",
        "billDate",
        "billCode",
        "goodsCode",
        "goodsName",
        "typeName",
        "goodsSpec",
        "goodsUnit",
        "billQuantity",
        "billPrice",
        "amount",
        "balanceQuantity",
        "balancePrice",
        "balanceAmount",
        "detailRemark",
      ];

      toExcel(header, column, list, "物资流水账");
    },
  },
};
</script>

<style>
</style>